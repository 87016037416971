function Header() {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1 className="hero-title">Something Amazing is Coming Soon!</h1>
        <p className="hero-subtitle">
          We are working hard to create something extraordinary. Stay tuned!
        </p>
        <div className="loader">
          <div className="loader-bar"></div>
        </div>
      </div>
    </div>
  );
}

export default Header;
